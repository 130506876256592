<template>
  <div id="signUpLogin">
    <div class="main">
      <div>
        <!-- 头部地理位置和搜索框 -->
        <div class="head">
          <!-- 顶部NavBar 开始 -->
          <van-nav-bar
            :title="$t('signLogin.title')"
            :left-text="$t('main.return')"
            left-arrow
            style="background-color: rgba(255,255,255,0);"
            @click-left="onClickLeft"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <!-- 表单 -->
        <div class="form">
          <van-form validate-first input-align="left" label-width="0px">
            <div class="form-top">
              <div class="paymonry">{{ $t('signLogin.partTitle') }}</div>
              <van-cell-group>
                <van-field
                  v-model="loginForm.username"
                  label=" "
                  :placeholder="$t('login.partTitle')"
                  label-width="0px"
                  clearable
                />
              </van-cell-group>
              <van-cell-group>
                <van-field
                  v-model="loginForm.password"
                  label=" "
                  :placeholder="$t('login.pwd')"
                  label-width="0px"
                  clearable
                  type="password"
                />
              </van-cell-group>
              <van-cell-group>
                <van-field
                  v-model="loginForm.verifycode"
                  maxlength="4"
                  label=" "
                  :placeholder="$t('login.v-code')"
                  label-width="0px"
                  clearable
                >
                  <template #button>
                    <div class="login-code2" @click="refreshCode">
                      <s-identify :identify-code="identifyCode" />
                    </div>
                  </template>
                </van-field>
              </van-cell-group>
            </div>
            <!-- 按钮组 -->
            <div class="btn-group">
              <van-button
                block
                type="info"
                native-type="submit"
                class="btn-class submit"
                @click="goToLogin"
              >
                {{ $t('login.login') }}
              </van-button>
              <van-button
                block
                type="warning"
                native-type="submit"
                class="btn-class submit register-btn"
                @click="register"
              >
                {{ $t('signLogin.register') }}
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
      <!-- 回到顶部按钮 -->
      <v-top />
    </div>
  </div>
</template>
<script>
import SIdentify from '@/components/Identify'

import store from '@/store'
import Cookies from 'js-cookie'
import Config from '@/settings'
import { getSchooPageList } from '@/api/registration/projectForRegistration'
// import store from '@/store'
import { Toast } from 'vant'
export default {
  name: 'SignUpLogin',
  components: {
    SIdentify
  },
  data() {
    return {
      registration: {
        amount: '',
        payTypeName: '',
        clock: null,
        totalTime: 60,
        showPayMode: true,
        qrcodeUrl: '',
        schoolList: [],
        registerTemp: {
          accountNum: '',
          passwordConfirm: '',
          password: '',
          phone: '',
          schoolCode: ''
        },
        nextStep: true,
        nextPayFlag: false,
        projectList: [],
        name: '',
        idserial: '',
        diyFormList: [],
        temp: {},
        diyRules: {},
        selectProId: ''
      },
      loginForm: {
        username: '',
        password: '',
        verifycode: '',
        loginStatus: false,
        accountType: 'BASE'
      },
      schoolName: '',
      schoolList: [],
      schoolShow: false,
      // 普通注册form
      normalRegisterForm: {
        accountNum: '',
        passwordConfirm: '',
        password: '',
        phone: '',
        schoolCode: '',
        schoolId: ''
      },
      form: {
        oldPass: '',
        newPass: '',
        confirmPass: '',
        accountNum: '',
        schoolCode: '',
        dataSource: '',
        userId: ''
      },
      loginForm2: {
        username: '',
        password: '',
        verifycode: '',
        loginStatus: false,
        accountType: 'BASE'
      },
      // 验证码随机数集合
      identifyCodes: '1234567890',
      // 定义验证码
      identifyCode: ''
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  beforeDestroy() {
    document.querySelector('body').setAttribute('style', 'background: #fff')
  },
  mounted() {
    // 验证码初始化
    this.identifyCode = ''
    // 随机获取4位长度的验证码
    this.makeCode(this.identifyCodes, 4)
  },
  methods: {
    // 注册
    register() {
      this.$router.push({ path: '/signRegister' })
    },
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    // 生成四位随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
    },
    // 验证码自定义验证规则
    validateVerifycode() {
      if (this.loginForm.verifycode === '') {
        Toast.fail(this.$t('msg.writeCode'))
        return false

        // 请输入验证码
      } else if (this.loginForm.verifycode !== this.identifyCode) {
        Toast.fail(this.$t('msg.wrongCode'))
        // 验证码不正确
        return false
      } else {
        return true
      }
    },
    goToLogin() {
      const verifycodePass = this.validateVerifycode()
      if (verifycodePass === true) {
        if (
          this.loginForm.username === null ||
        this.loginForm.password === null ||
        this.loginForm.username === '' ||
        this.loginForm.password === ''
        ) {
          Toast.fail(this.$t('msg.login'))
          return false
        } else {
          var param = JSON.parse(JSON.stringify(this.loginForm))
          param.password = this.$md5(this.loginForm.password)
          // console.log(param)
          param.loginStatus = Boolean(param.loginStatus)
          if (param.loginStatus) {
            Cookies.set('username', param.username, { expires: Config.passCookieExpires })
            Cookies.set('password', param.password, { expires: Config.passCookieExpires })
            Cookies.set('loginStatus', param.loginStatus, { expires: Config.passCookieExpires })
          } else {
            Cookies.remove('username')
            Cookies.remove('password')
            Cookies.remove('loginStatus')
          }
          this.$store.dispatch('user/login', param).then((response) => {
            store.dispatch('user/getInfo').then(res => { // 拉取user_info
              this.registration.name = res.name
              this.registration.idserial = res.idserial
              localStorage.setItem('name', res.name)
              localStorage.setItem('idserial', res.idserial)
            }).catch((err) => {
              console.log(err)
              store.dispatch('user/logout').then(() => {
                location.reload() // 为了重新实例化vue-router对象 避免bug
              })
            })
            // 跳转详情页操作
            this.$router.push({ path: 'signDetail' })
            // this.getProjectInfo()
            // 判断此项目批次是否存在未缴费订单
          }).catch(err => {
            Toast.fail(err.message)
            // this.loading = false
          })
        }
      }
    },
    //   获取学校
    getSchooPageList() {
      getSchooPageList(
        1,
        100
      ).then(response => {
        // 给list新增text属性 schoolName   只有vant这个框架只接收text
        const tempList = response.data.data.records
        for (let i = 0; i < tempList.length; i++) {
          this.$set(tempList[i], 'text', tempList[i].schoolName)
        }
        this.schoolList = tempList
      }).catch(err => {
        Toast.fail(err.message)
      })
    },
    onSubmit() {

    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: 'signUp' })
    }
  }
}
</script>
<style scoped lang="scss">
.btn-class{
  border-radius: 6px;
}
.register-btn{
  background: #fff;
  color: #2F9FF2;
  border: 1px solid #2F9FF2;
}
.paymonry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  // margin: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}
.form-top{
  padding: 20px;
  padding-bottom: 60px;
  background: #fff;
  border-radius: 10px;
}
.van-cell-group{
  border-bottom:1px solid #D0D0D0 ;
}
.form{
   border-radius:  10px 10px 0 0;
   overflow: hidden;
}
#signUpLogin {
  min-height: 667px;
  background: #f5f5f5;
  .van-cell {
  line-height: 30px;
  }
  .van-icon__image {
    width: 40px;
    height: 30px;
}
  position: relative;
  .head {
    height: 84px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding:20px 30px;
  // padding: 0px 10px 10px 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  margin: 10px 15px 0 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.btn-group{
  margin-top:40px;
  .btn-class{
    margin: 10px 0;
  }
  .btn-text{
    color: #2F9FF2;
  }
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  position: fixed;
  bottom:0;
  left: 0;
  width: 100%;
  background: #ffffff;
  height: 80px;
  margin-top:20px;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
.van-cell__value {
  span{
    color: #333333;
  }
}
#totalpay {
  .van-cell__value {
  span{
    color: #3BA4F2;
  }
}
}
</style>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
</style>
<style lang="scss" scoped>
.main{
  margin-bottom:50px;
}
.box{
  width: 86%;
  margin:0 auto;
  padding:0 10%;
  height:120px;
  background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
  border-radius:12px;
}
.img-box{
width:60px;
height:60px;
border:1px solid #ffffff;
background:#fff;
border-radius:50% ;
margin-top:32px;
}
.text-box{
margin-top:20px;

  p {
    font-size:14px;
    line-height: 30px;
    height: 20px;
    color: rgba(216,235,244,1);
    span{
    color: #FFFFFF;
    }
  }
}
</style>
